import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
   
    fetchMessages(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/daily-messages', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchMessage(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios

          .get(`/daily-messages/view/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    addMessage(ctx, data) {
      const bodyFormData = new FormData()

      bodyFormData.append('title_en', data.title_en)
      bodyFormData.append('title_ar', data.title_ar)
      bodyFormData.append('message_en', data.message_en)
      bodyFormData.append('message_ar', data.message_ar)
    


      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/daily-messages/create',
          data: bodyFormData,
          headers: {
            contentType: 'multipart/form-data',
          },
        })
          .then(response => resolve(response))
          .catch(error => {
            reject(error)
          })
      })
    },
    editMessage(ctx, data) {
      const bodyFormData = new FormData()
      bodyFormData.append('message_id', data.id)
      bodyFormData.append('title_en', data.title_en)
      bodyFormData.append('title_ar', data.title_ar)
      bodyFormData.append('message_en', data.message_en)
      bodyFormData.append('message_ar', data.message_ar)
  

      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/daily-messages/update',
          data: bodyFormData,
        })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    deleteMessage(_ctx, _id) {
      const data = { message_id: _id }

      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/daily-messages/delete',
          data,
        })
          .then(response => resolve(response))
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
