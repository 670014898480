<template>
  <v-navigation-drawer :value="isAddNewMessageSidebarActive" :permanent="isAddNewMessageSidebarActive" temporary touchless :right="!$vuetify.rtl" :width="'20%'" app
    @input="(val) => $emit('update:is-add-new-item-sidebar-active', val)">
    <v-card height="100%">
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary">Add New Message</span>
        <v-spacer></v-spacer>
        <v-btn icon small @click="$emit('update:is-add-new-item-sidebar-active', false)">
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
          <v-row>
            <v-col cols="12" sm="12">
              <v-text-field v-model="itemData.title_en" :rules="[validators.customeRequired]" outlined dense label="Title En"
                placeholder="Title En" hide-details="auto" class="mb-6"></v-text-field>

                <v-text-field v-model="itemData.title_ar" :rules="[validators.customeRequired]" outlined dense label="Title Ar"
                placeholder="Title Ar" hide-details="auto" class="mb-6"></v-text-field>

              <v-textarea v-model="itemData.message_en" outlined label="Message En" :rules="[validators.required]"
                placeholder="Message En">
              </v-textarea>

              <v-textarea v-model="itemData.message_ar" outlined label="Message Ar" :rules="[validators.required]"
                placeholder="Message Ar">
              </v-textarea>

            </v-col>
          </v-row>

          <v-btn color="primary" class="me-3" type="submit">
            Add
          </v-btn>
          <v-btn color="secondary" outlined type="reset" @click="resetItemData">
            Cancel
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import store from '@/store'
import {
  customeRequired,
  emailValidator,
  fileRequired,
  imageValidator,
  integerValidator,
  required,
} from '@core/utils/validation'
import { mdiClipboardFileOutline, mdiClose, mdiDelete, mdiPlus } from '@mdi/js'
import { ref } from '@vue/composition-api'
import Swal from 'sweetalert2'

export default {
  model: {
    prop: 'isAddNewMessageSidebarActive',
    event: 'update:is-add-new-item-sidebar-active',
  },
  props: {
    isAddNewMessageSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const modal = ref(false)
    const menu2 = ref(false)

    var date = new Date()
    var dd = String(date.getDate()).padStart(2, '0')
    var mm = String(date.getMonth() + 1).padStart(2, '0') //January is 0!
    var yyyy = date.getFullYear()
    const today = yyyy + '-' + mm + '-' + dd

    const blankItemData = {
      title_en: '',
      title_ar: '',
      message_ar: '',
      message_en: '',
    }

    const isError = ref(false)
    const valid = ref(false)
    const form = ref(null)

    const validate = () => {
      form.value.validate()
    }

    const resetForm = () => {
      form.value.reset()
    }

    const itemData = ref(JSON.parse(JSON.stringify(blankItemData)))
    const resetItemData = () => {
      itemData.value = JSON.parse(JSON.stringify(blankItemData))
      resetForm()
      emit('update:is-add-new-item-sidebar-active', false)
    }

    const onSubmit = () => {
      if (valid.value) {
        store
          .dispatch('app-messages/addMessage', itemData.value)
          .then(response => {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: response.data.data.message,
              showConfirmButton: false,
              timer: 1500,
            })
            emit('refetch-data')
            emit('update:is-add-new-item-sidebar-active', false)
          })
          .catch(error => {
            Object.entries(error.data.message).forEach(err => {
              Swal.fire({
                icon: 'error',
                title: `${err[0]}`,
                text: `${err[1][0]}`,
                footer:
                  'Desired dimensions: min-width: 833px; max-width: 1670px; min-height: 625px; max-height: 1253px; max-file-size: 512kb',
              })
            })
          })
        resetItemData()
      } else {
        validate()
      }
    }

    const handleAler = () => {
      isError.value = false
    }

    return {
      resetItemData,
      form,
      today,
      onSubmit,
      itemData,
      valid,
      validate,
      isError,
      handleAler,
      blankItemData,
      // validation
      validators: {
        integerValidator,
        required,
        imageValidator,
        fileRequired,
        customeRequired,
        emailValidator,
      },
      icons: {
        mdiClose,
        mdiClipboardFileOutline,
        mdiPlus,
        mdiDelete,
      },
    }
  },
}
</script>
