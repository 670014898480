import { isEmpty } from './index'

export const required = value => {
  value = value?.toString()
  return value && value.length ? true : 'This field is required'
}

export const textNOspace = value => {
  value = value?.toString()
  if (value && value.length) {
    if (value.includes(' ')) {
      return "User Name Don't accept spaces"
    }else{
      return true
    }
  }else{
    return 'This field is required'
  }
}
export const textareaRule = value => {
  if (value) {
    if (value.length <= 250) {
      return true
    } else {
      return 'Max 250 characters'
    }
  }
  return 'THIS FIELD IS REQUIRED'
}
export const customeRequired = value => 
  (value && value.toString().length) || value === 0 ? true : 'This field is required'

export const checkboxValidation = (value, data) => {
  if (value || data) {
    return true
  } else {
    if (!data) {
      return true
    }
    return 'This field is required'
  }
}

export const decimal_integer_Validator = value => {
  if (isEmpty(value)) {
    return 'This field is required'
  }
  // eslint-disable-next-line
  const re = /^[-+]?[0-9]+\.[0-9]+$/.test(String(value))
  const re2 = /^-?[0-9]+$/.test(String(value))

  if (Array.isArray(value)) {
    let one = value.every(val => re.test(String(val)))
    let two = value.every(val => re2.test(String(val)))
    return one || two
  }

  if (re || re2) {
    return true
  } else {
    return 'This field must be an decimal or integer'
  }
}

export const decimal_integer_Validator_not_required = value => {
  if (isEmpty(value)) {
    return true
  }
  // eslint-disable-next-line
  const re = /^[-+]?[0-9]+\.[0-9]+$/.test(String(value))
  const re2 = /^-?[0-9]+$/.test(String(value))

  if (Array.isArray(value)) {
    let one = value.every(val => re.test(String(val)))
    let two = value.every(val => re2.test(String(val)))
    return one || two
  }

  if (re || re2) {
    return true
  } else {
    return 'This field must be an decimal or integer'
  }
}

export const decimal_Validator = value => {
  if (isEmpty(value)) {
    return true
  }
  // eslint-disable-next-line
  const re = /^[-+]?[0-9]+\.[0-9]+$/.test(String(value))

  if (Array.isArray(value)) {
    let one = value.every(val => re.test(String(val)))
    return one
  }

  if (re) {
    return true
  } else {
    return 'This field must be an decimal'
  }
}

export const fileRequired = value => {
  if (value === null || value.size === 0) return 'This field is required'
  if (value.size > 0 && value.size < 512000) return true
  if (value.size > 512000) return 'Max Image Size Must no Exceed 512KB'

  // Object.keys(value).length > 0 ? true : 'This field is required'
}
export const emailValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  // eslint-disable-next-line
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  if (Array.isArray(value)) {
    return value.every(val => re.test(String(val)))
  }

  return re.test(String(value)) || 'The Email field must be a valid email'
}

export const passwordValidator = password => {
  /* eslint-disable no-useless-escape */
  const regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/
  /* eslint-enable no-useless-escape */
  const validPassword = regExp.test(password)

  return (
    // eslint-disable-next-line operator-linebreak
    validPassword ||
    'Field must contain at least one uppercase, lowercase, special character and digit with min 8 chars'
  )
}

export const validtelfun = (value, validtel) => {
  value = value?.toString()
  if (value && value.length) {
    if (validtel) {
      return true
    } else {
      return 'Real number required'
    }
  }
  return 'This field is required'
}

export const confirmedValidator = (value, target) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  value === target || 'The Confirm Password field confirmation does not match'

export const between = (value, min, max) => () => {
  const valueAsNumber = Number(value)

  return (Number(min) <= valueAsNumber && Number(max) >= valueAsNumber) || `Enter number between ${min} and ${max}`
}

export const integerValidator = value => {
  if (isEmpty(value)) {
    return 'This field is required'
  }

  if (Array.isArray(value)) {
    return value.every(val => /^-?[0-9]+$/.test(String(val)))
  }

  return /^-?[0-9]+$/.test(String(value)) || 'This field must be an integer'
}

export const integerValidator_not_required = value => {
  if (isEmpty(value)) {
    return true
  }

  if (Array.isArray(value)) {
    return value.every(val => /^-?[0-9]+$/.test(String(val)))
  }

  return /^-?[0-9]+$/.test(String(value)) || 'This field must be an integer'
}

export const regexValidator = (value, regex) => {
  if (isEmpty(value)) {
    return true
  }

  let regeX = regex
  if (typeof regeX === 'string') {
    regeX = new RegExp(regeX)
  }

  if (Array.isArray(value)) {
    return value.every(val => regexValidator(val, { regeX }))
  }

  return regeX.test(String(value)) || 'The Regex field format is invalid'
}

export const alphaValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  // const valueAsString = String(value)

  return /^[A-Z]*$/i.test(String(value)) || 'The Alpha field may only contain alphabetic characters'
}

export const urlValidator = value => {
  if (value === undefined || value === null || value.length === 0) {
    return true
  }
  /* eslint-disable no-useless-escape */
  const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/

  return re.test(value) || 'URL is invalid'
}

export const lengthValidator = (value, length) => {
  if (isEmpty(value)) {
    return true
  }

  return value.length === length || `The Min Character field must be at least ${length} characters`
}

export const minLengthValidator = (value, length) => {
  if (isEmpty(value)) {
    return true
  }

  return value.length >= length || `The Min Character field must be at least ${length} characters`
}
export const alphaDashValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  const valueAsString = String(value)

  return /^[0-9A-Z_-]*$/i.test(valueAsString) || 'All Character is not valid'
}

export const imageValidator = value => {
  if (value === null || !value.size) return 'This Field is Required'
  if (value.size > 512000) return 'Avatar size should be less than 512KB!'

  return true
}
export const maxLength = value => {
  if (value.length < 2 || value.length > 400) return 'The Max Character field must between 2 and 400 characters'
}
